import React from 'react';
import { Helmet } from 'react-helmet';

export interface SEOProps {
  pageDescription?: string;
  pageImage?: string;
  pageTitle?: string;
  pageType?: string;
  pageUrl?: string;
}

const SEO: React.FC<SEOProps> = ({
  pageDescription,
  pageImage,
  pageTitle,
  pageType = 'website',
  pageUrl,
}) => {
  return (
    <Helmet>
      <title>{pageTitle}</title>
      {pageDescription && <meta name="description" content={pageDescription} />}
      {pageUrl && <link rel="canonical" href={pageUrl} />}

      {/* Open Graph */}
      {pageType && <meta property="og:type" content={pageType} />}
      {pageTitle && <meta property="og:title" content={pageTitle} />}
      {pageDescription && <meta property="og:description" content={pageDescription} />}
      {pageUrl && <meta property="og:url" content={pageUrl} />}
      {pageImage && <meta property="og:image" content={pageImage} />}

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary" />
      {pageTitle && <meta name="twitter:title" content={pageTitle} />}
      {pageDescription && <meta name="twitter:description" content={pageDescription} />}
      {pageUrl && <meta name="twitter:url" content={pageUrl} />}
      {pageImage && <meta name="twitter:image" content={pageImage} />}
    </Helmet>
  );
};

export default SEO;
