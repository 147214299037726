import SEO from '@connected/SEO';
import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import React from 'react';
import { ContentModels } from 'schema/ContentModels';

const SectionsAsync = loadable(() => import('@components/connected/Sections'));

interface IndexPageProps {
  data: {
    home: ContentModels.ContentfulHomePage;
  };
}

const IndexPage: React.FC<IndexPageProps> = ({ data }) => {
  return (
    <>
      <SEO data={data.home.metadata} url="/" />
      <SectionsAsync sections={data.home.sections} />
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPageQuery {
    home: contentfulHomePage {
      contentful_id
      metadata {
        ...ContentfulMetadataFragment
      }
      sections {
        ...LinkedItemsFragment
      }
      title
    }
  }
`;
