import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import SEOPres, { SEOProps as SEOPresProps } from '@presentation/SEO';
import { ContentModels } from 'schema/ContentModels';
import imageUrl from '@utils/cloudinary';

export interface SEOProps {
  data?: ContentModels.ContentfulMetadata;
  url?: string;
}

export interface GlobalMetadataQuery {
  master: ContentModels.ContentfulMaster;
  site: {
    siteMetadata: {
      origin: string;
    };
  };
}

const SEO: React.FC<SEOProps> = ({ data, url }) => {
  const masterData = useGlobalMetadataQuery();
  const masterMetadata = masterData.master.metadata as ContentModels.ContentfulMetadata | undefined;

  const metaImageId = data?.pageImage?.[0]?.public_id ?? masterMetadata?.pageImage?.[0]?.public_id;

  const model: SEOPresProps = {
    pageDescription: data?.pageDescription ?? masterMetadata?.pageDescription,
    pageImage: imageUrl(metaImageId, 'c_fill,g_auto,w_1200,h_627,f_jpg'),
    pageTitle: getPageTitle(data?.pageTitle, masterMetadata?.pageTitle),
    pageUrl: url ? new URL(url, masterData.site.siteMetadata.origin).href : undefined,
  };

  return <SEOPres {...model} />;
};

export default SEO;

export const ContentfulMetadataFragment = graphql`
  fragment ContentfulMetadataFragment on ContentfulMetadata {
    id
    pageDescription
    pageTitle
  }
`;

function getPageTitle(prefix?: string, suffix?: string): string {
  if (prefix) {
    if (suffix) {
      return `${prefix} | ${suffix}`;
    }

    return prefix;
  }

  return suffix ?? '';
}

function useGlobalMetadataQuery(): GlobalMetadataQuery {
  return useStaticQuery<GlobalMetadataQuery>(graphql`
    query GlobalMetadataQuery {
      master: contentfulMaster {
        metadata {
          ... on ContentfulMetadata {
            ...ContentfulMetadataFragment
          }
        }
      }
      site {
        siteMetadata {
          origin
        }
      }
    }
  `);
}
